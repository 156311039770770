<template>
  <div class="warp">
    <el-form >
    <div class="match-info">
      <span>{{ originMapping[$route.query.dataSourceCode]}}</span>
      <span>{{$route.query.sourceSportsName || $route.query.thirdSport}}</span>
      <span>{{$route.query.sourceRegionName || $route.query.thirdRegion}}</span>
      <span>{{$route.query.sourceTournamentName || $route.query.thirdTournament}}</span>
      <span>{{$route.query.beginTime | formatTime}}</span> <br>
      <span>{{$route.query.name}}</span>
    </div>
     </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
      @expand-change="expandChange"
    >
            <el-table-column
              label="Source ID"
              width="120"
              align="center"
              prop="sourceTeamId"
            >
            <template v-slot="{row}">
              {{isPlayer ? row.sourcePlayerId : row.sourceTeamId}}
            </template>
            </el-table-column>
            <el-table-column
              label="Source name"
              width="140"
              align="center"
              prop="sourcePlayerName"
            ></el-table-column>
            <el-table-column
              :label="`${isPlayer ? 'Player': 'Team'} Name`"
              width="180"
              align="center"
              prop="standardPlayerEngName"
            ></el-table-column>
      
      <el-table-column
        :label="`${isPlayer ? 'Player': 'Team'} ID`"
        width="140"
        align="center"
        prop="standardPlayerId"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="CMN"
        min-width="130"
        align="center"
        prop="standardPlayerCmnName"
      >
       <template v-slot="{ row }">
             {{isPlayer ? row.standardPlayerCmnName : row.sportsName}}
              </template>
      </el-table-column>
      <el-table-column v-if="isPlayer" label="Logo" align="center" prop="logoUrl">
        <template v-slot="{ row }">
          <img
            class="table-team-logo"
            v-if="row.logoUrl"
            :src="row.logoUrl"
            alt="team-logo"
          />
        </template>
      </el-table-column>
       <!-- <el-table-column
        v-if="isPlayer"
        :label="`${isPlayer ? 'Player': 'Team'} Name`"
        min-width="180"
        align="center"
        prop="name"
      ></el-table-column> -->
      <el-table-column v-if="isPlayer" label="Club Team" min-width="160" prop="clubTeamName">
      </el-table-column>
      <el-table-column v-if="isPlayer" min-width="130" label="Club Number" prop="clubNumber">
      </el-table-column>
      <el-table-column v-if="isPlayer" label="Country Team" min-width="160" prop="regionTeamName">
      </el-table-column>
      <el-table-column v-if="isPlayer" min-width="130" label="Country Number" prop="regionNumber">
      </el-table-column>
      <!-- <el-table-column label="Repush" min-width="160" align="center">
        <template v-slot="{ row }">
           <el-button
           type="text"
            @click="changeRepushStatus(row, $event)"
          >Repush</el-button>
        </template>
      </el-table-column> -->
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        label="Internationalization"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="editLang(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row> -->
        <el-dialog
        v-if="editType === 2"
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="120px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Player ID:" v-if="String(dialogForm.id)">{{
          dialogForm.id
        }}</el-form-item>
        <el-form-item
          v-for="item in langData"
          :prop="`language${item}`"
          :key="item"
          :label="item + ':'"
        >
          <el-input
            v-if="dialogForm.language"
            v-model="dialogForm.language[item]"
            placeholder="Please typing in text"
            style="width:300px"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark:">
          <el-input
            v-model="dialogForm.remark"
            placeholder="Please typing in text"
            style="width:300px"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="dialog.isLoading" @click="submit"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      v-if="editType === 1"
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
      :destroy-on-close="true"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="144px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item :label="`${isPlayer ? 'Player': 'Team'} ID:`" v-if="dialogForm.id">
          {{ dialogForm.id }}
        </el-form-item>
        <el-form-item :label="`${isPlayer ? 'Player': 'Team'} Name:`" prop="name" required>
          <el-input
            placeholder="Please typing in team name"
            v-model="dialogForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Short Name:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in short name"
            v-model="dialogForm.shortName"
          ></el-input>
        </el-form-item>
        <el-form-item label="Sports:" prop="sportId" required>
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.sportId"
            :label="dialogForm.sportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Tournament:" prop="tournamentId">
          <select-box
            :url="STD_SEARCH_TOURNAMENT"
            :queryOrderParams="{ page: 1, size: 100 }"
            :value.sync="dialogForm.tournamentId"
            :label="dialogForm.tournament"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Club Team ID" v-if="isPlayer">
             <el-input
                v-model="dialogForm.clubTeamId"
                @change="inputTeam($event, 0)"
                style="width: 120px"
                placeholder="Input"
              ></el-input>
              <label class="el-form-item__label" style="width: 50px;float:none;padding-left:10px">Name</label>
              <select-box
                key="clubTeam"
                :url="STD_SEARCH_TEAM"
                ref="clubTeam"
                width="127"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1,
                  sportId: dialogForm.sportId
                }"
                :label="dialogForm.clubTeamName"
                :value.sync="dialogForm.clubTeamId"
                search-key="name"
                label-key="name"
                value-key="id"
              />
        </el-form-item>
        <el-form-item label="Club Number" v-if="isPlayer">
          <el-input v-model="dialogForm.clubNumber" placeholder="Input" type="number"></el-input>
        </el-form-item>
        <el-form-item label="Country Team ID" v-if="isPlayer">
             <el-input
                v-model="dialogForm.regionTeamId"
                @change="inputTeam($event, 1)"
                style="width: 120px"
                placeholder="Input"
              ></el-input>
            <label class="el-form-item__label" style="width: 50px;float:none;padding-left:10px">Name</label>
            <select-box
                :url="STD_SEARCH_TEAM"
                ref="countryTeam"
                width="127"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1,
                  sportId: dialogForm.sportId
                }"
                :label="dialogForm.regionTeamName"
                :value.sync="dialogForm.regionTeamId"
                search-key="name"
                label-key="name"
                value-key="id"
              />
        </el-form-item>
        <el-form-item label="Country Number" v-if="isPlayer">
          <el-input v-model="dialogForm.regionNumber" placeholder="Input" type="number"></el-input>
        </el-form-item>
        <el-form-item v-if="!isPlayer" label="Standard Region:" prop="regionId">
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
            :value.sync="dialogForm.regionId"
            :label="dialogForm.regionName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Logo:">
          <logo-upload :url.sync="dialogForm.logoUrl" />
        </el-form-item>
        <el-form-item label="Coach:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in coach"
            v-model="dialogForm.coach"
          ></el-input>
        </el-form-item>
        <el-form-item label="Stadium:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in stadium"
            v-model="dialogForm.stadium"
          ></el-input>
        </el-form-item>
        <el-form-item label="Introduction:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in Introduction"
            v-model="dialogForm.introduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark:">
          <el-input
            placeholder="Please typing in remark"
            v-model="dialogForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="dialog.isLoading"
            @click="dialogSubmit"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTeamList,
  standardPlayerList,
  addTeam,
  savePlayer,
  editTeam,
  updateStandardPlayer,
  editTeamRegion,
  getThirdTeamById,
  updateThirdTeamById,
  teamValidity,
  getThirdPlayerById,
  updateVisiblePlayer,
  updateThirdPlayerById,
  getTeamList as findTeam,
  teamRePush,
  playerRePush,
  editInternational,
  queryPlayerInternational,
  getPlayerFromPlayerMarkets
} from '@/service/standard'
import TableMixin from '@/mixins/standard/tableSelect'
import { Dropdown, InputAutocomplete, LogoUpload } from '@/components'
import { cloneDeep } from 'lodash'
import StatusIcon from '@/components/StatusIcon'
import {originMapping, langData} from '@/utils/constants'
export default {
  mixins: [TableMixin],
  components: { LogoUpload },
  data() {
    return {
      originMapping,
      methodStr: 'editTeamRemark',
      editRowIndex: null,
      extraData: {},
      expandData: {},
      editType: 0,
      langData,
      rules: {
        name: [
          {
            message: 'Please typing in Team name',
            required: true,
            pattern: '[^ \x22]+'
          }
        ],
        sportId: [
          {
            message: 'Please select sports',
            required: true
          }
        ]
      }
    }
  },
  watch: {
    'extraData.id' () {
      if (this.extraData.id) {
        this.extraData.name = ''
        this.extraData.sportId = ''
        this.extraData.regionId = ''
      }
    }
  },
  computed: {
    isPlayer() {
      return this.$route.path.indexOf('player') > -1
    }
  },
  methods: {
    editLang(info) {
      this.editType = 2

      queryPlayerInternational({id: info.standardPlayerId}).then(res => {
        if(res.code === 0) {
          const row = res.data.records[0] || {}
          this.dialogEdit({
                id: row.id,
                nameCode: row.nameCode,
                language: row.language,
                remark: row.remark
          })
        }
      })
    },
     submit() {
      const language = this.dialogForm.language
      this.dialogForm.json = JSON.stringify(language)
      this.dialogForm.libraryType = 13
      this.dialogSubmit(this.dialogForm.language)
    },
    inputTeam(e, type = 1) {
      console.log(e, type)
      if(!e) {
        return
      }
      const refName = type === 1 ? 'countryTeam' : type == 0 ? 'clubTeam' : type === 3? 'selectTournament': 'teamSelect';
      (type === 3 ? getTournamentList({id: e}) : findTeam({ id: e })).then((res) => {
        if (res.code === 0) {
          const list = res.data.records
          if(list.length === 0) {
            if(type === 1) {
              this.dialogForm.homeTeamId = ''
            }
            if(type === 0) {
              this.dialogForm.clubTeamId = ''
            }
            if(type === 3) {
              this.dialogForm.tournamentId = ''
            }
            return
          }
          const [{ name }] = list
          this.$refs[refName].optionData = list
          this.$nextTick((e) => {
            this.$refs[refName].$refs.select.selectedLabel = name
          })
        }
      })
    },
    changeRepushStatus(row) {
      const apiFn = this.isPlayer ? playerRePush : teamRePush
      apiFn(row.id).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
    },
    countryTeamChange(isId){
      let inputTarget = this.$refs.countryTeamId.$children[0]
      if(isId){
        inputTarget = this.$refs.countryTeam.$children[0]
      }
      inputTarget.focus()
      this.$nextTick(() => {
        inputTarget.blur()
      })
    },
    clubTeamChange(isId){
      let inputTarget = this.$refs.clubTeamId.$children[0]
      if(isId){
        inputTarget = this.$refs.clubTeam.$children[0]
      }
      inputTarget.focus()
      this.$nextTick(() => {
        inputTarget.blur()
      })
    },
     getData(...rest) {
       return getPlayerFromPlayerMarkets(...rest)
    },
    editData(...rest) {
      if (this.editType === 1) {
       return updateStandardPlayer(...rest)
      } else {
       return editInternational(...rest)
      }
    },
    addData(...rest) {
      if (this.isPlayer) {
       return savePlayer(...rest)
      } else {
       return addTeam(...rest)
      }
    },
    editTeamRegion,
    handleEdit(index) {
      this.editRowIndex = index
    },
    getList() {
      this.loading = true
      const {sourceMatchId, dataSourceCode} =this.$route.query
      
      const extraData = this.fliterParams(this.extraData) || {}
      this.getData({ ...this.queryData, ...extraData, thirdMatchId:sourceMatchId.split(':')[1], dataSourceCode })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            this.list = data
            this.list = this.list.map(item => ({
              ...item,
              switchStatus: item.isVisible === 1,
            }))
            // this.total = data.total
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    },
    edit(row) {
      const {clubTeamName,clubTeamId, clubNumber,regionTeamName,regionTeamId,regionNumber} = row
      const data = {
        id: row.standardPlayerId,
        nameCode: row.nameCode,
        remark: row.remark,
        sportId: row.sportId,
        sportsName: row.sportName || row.standardSportName,
        regionId: row.regionId,
        regionName: row.regionName,
        tournamentId: row.tournamentId,
        tournament: row.tournamentName || row.standardTournamentName,
        name: row.name,
        [this.isPlayer? 'oldPlayerName' : 'oldTeamName']: row.name,
        shortName: row.shortName,
        coach: row.coach,
        stadium: row.stadium,
        logoUrl: row.logoUrl,
        introduction: row.introduction,
        clubTeamName,clubTeamId, clubNumber,regionTeamName,regionTeamId,regionNumber
      }
      this.editType = 1
      this.dialogEdit(data)
    },
    expandChange(row) {
      let { id } = row
      this.upDataExpandData(id, {
        loading: true,
        data: null
      });
      (this.isPlayer ? getThirdPlayerById({standardPlayerId: id}) : getThirdTeamById({standardTeamId: id})).then(res => {
          this.upDataExpandData(id, {
            loading: false,
            data: res.data
          })
        })
        .catch(() => {
          this.upDataExpandData(id, {
            loading: false,
            data: null
          })
        })
    },
    upDataExpandData(id, data) {
      console.log(data)
      let expandData = cloneDeep(this.expandData)
      expandData[id] = data
      this.expandData = expandData
    },
    remove (row, propsRow) {
      this.$msgbox({
        title: '',
        type: 'warning',
        message: `Remove the mapping ?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(action => {
        (this.isPlayer ? updateThirdPlayerById({thirdPlayerId: row.id}) : updateThirdTeamById({thirdTeamId: row.id})).then(res => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.expandChange(propsRow)
          }
        })
      })
    },
    switchHandle(row) {
      if (row.switchStatus) {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to invalid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
        (this.isPlayer? updateVisiblePlayer({playerId: row.id, visible: row.visible ? 1 : 0}) : teamValidity({teamId: row.id, validity: row.switchStatus ? 1 : 0})).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      } else {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to Valid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
         (this.isPlayer? updateVisiblePlayer({playerId: row.id, visible: row.visible ? 1 : 0}) : teamValidity({teamId: row.id, validity: row.switchStatus ? 1 : 0})).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-team-logo {
  height: 32px;
  width: 32px;
}
/deep/ .el-table__expanded-cell {
  padding-left: 10px;
}
.regionClass {
  span {
    margin-right: 5px;
  }
}
.match-info {
  margin-bottom: 20px;
  > span{
    margin: 10px;
    color: #333;
    &::after{
      content: '|';
      margin-left: 10px;
    }
    &:nth-child(5) {
       &::after {
        display: none;
      }
    }
    &:last-child{
      font-weight: bold;
      &::after {
        display: none;
      }
    }
  }
}
</style>
